// jquery.easing.1.3.js

jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend( jQuery.easing,
{
	def: 'easeOutQuad',
	swing: function (x, t, b, c, d) {
		//alert(jQuery.easing.default);
		return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
	},
	easeInQuad: function (x, t, b, c, d) {
		return c*(t/=d)*t + b;
	},
	easeOutQuad: function (x, t, b, c, d) {
		return -c *(t/=d)*(t-2) + b;
	},
	easeInOutQuad: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t + b;
		return -c/2 * ((--t)*(t-2) - 1) + b;
	},
	easeInCubic: function (x, t, b, c, d) {
		return c*(t/=d)*t*t + b;
	},
	easeOutCubic: function (x, t, b, c, d) {
		return c*((t=t/d-1)*t*t + 1) + b;
	},
	easeInOutCubic: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t + b;
		return c/2*((t-=2)*t*t + 2) + b;
	},
	easeInQuart: function (x, t, b, c, d) {
		return c*(t/=d)*t*t*t + b;
	},
	easeOutQuart: function (x, t, b, c, d) {
		return -c * ((t=t/d-1)*t*t*t - 1) + b;
	},
	easeInOutQuart: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
		return -c/2 * ((t-=2)*t*t*t - 2) + b;
	},
	easeInQuint: function (x, t, b, c, d) {
		return c*(t/=d)*t*t*t*t + b;
	},
	easeOutQuint: function (x, t, b, c, d) {
		return c*((t=t/d-1)*t*t*t*t + 1) + b;
	},
	easeInOutQuint: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
		return c/2*((t-=2)*t*t*t*t + 2) + b;
	},
	easeInSine: function (x, t, b, c, d) {
		return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
	},
	easeOutSine: function (x, t, b, c, d) {
		return c * Math.sin(t/d * (Math.PI/2)) + b;
	},
	easeInOutSine: function (x, t, b, c, d) {
		return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
	},
	easeInExpo: function (x, t, b, c, d) {
		return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
	},
	easeOutExpo: function (x, t, b, c, d) {
		return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
	},
	easeInOutExpo: function (x, t, b, c, d) {
		if (t==0) return b;
		if (t==d) return b+c;
		if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
		return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
	},
	easeInCirc: function (x, t, b, c, d) {
		return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;
	},
	easeOutCirc: function (x, t, b, c, d) {
		return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
	},
	easeInOutCirc: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
		return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
	},
	easeInElastic: function (x, t, b, c, d) {
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
	},
	easeOutElastic: function (x, t, b, c, d) {
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
	},
	easeInOutElastic: function (x, t, b, c, d) {
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d/2)==2) return b+c;  if (!p) p=d*(.3*1.5);
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
		return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
	},
	easeInBack: function (x, t, b, c, d, s) {
		if (s == undefined) s = 1.70158;
		return c*(t/=d)*t*((s+1)*t - s) + b;
	},
	easeOutBack: function (x, t, b, c, d, s) {
		if (s == undefined) s = 1.70158;
		return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
	},
	easeInOutBack: function (x, t, b, c, d, s) {
		if (s == undefined) s = 1.70158;
		if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
		return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
	},
	easeInBounce: function (x, t, b, c, d) {
		return c - jQuery.easing.easeOutBounce (x, d-t, 0, c, d) + b;
	},
	easeOutBounce: function (x, t, b, c, d) {
		if ((t/=d) < (1/2.75)) {
			return c*(7.5625*t*t) + b;
		} else if (t < (2/2.75)) {
			return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
		} else if (t < (2.5/2.75)) {
			return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
		} else {
			return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
		}
	},
	easeInOutBounce: function (x, t, b, c, d) {
		if (t < d/2) return jQuery.easing.easeInBounce (x, t*2, 0, c, d) * .5 + b;
		return jQuery.easing.easeOutBounce (x, t*2-d, 0, c, d) * .5 + c*.5 + b;
	}
});











// Swiper
$(document).ready(function () {
  // $(this).scrollTop(0);

  var clientsSwiper = new Swiper('.clients-list-slider', {
    slidesPerView: 'auto',
    loop: true,
    freeMode: true,
    navigation: {
      nextEl: '.clients-and-certificates .next',
      prevEl: '.clients-and-certificates .prev',
    },
    // autoplay: {
    //   delay: 200,
    // },
  });

  var banksSwiper = new Swiper('.banks-list-slider', {
    slidesPerView: 'auto',
    loop: true,
    freeMode: true,
    autoplay: {
      delay: 2000,
    },
  });

  var cardsSwiper = new Swiper('.payment-card-slider', {
    slidesPerView: 'auto',
    loop: true,
    freeMode: true,
    // centeredSlides: true,
  });

  var methodsSwiper = new Swiper('.methods-slider', {
    loop: true,
    autoHeight: true,
    speed: 600,
    pagination: {
      el: '.methods-slider-pagination',
    },
  });


  // methods slider control
  $(".methods-slider-control__item").click(function() {
    // `this` is the DOM element that was clicked
    var index = $( ".methods-slider-control__item" ).index(this);
    // alert(index + 1);

    $( ".methods-slider-control__item" ).removeClass('active');
    $(this).addClass('active');

    methodsSwiper.slideTo(index + 1)
  });

  methodsSwiper.on('slideChange', function () {
    var state = methodsSwiper.realIndex;
    $( ".methods-slider-control__item" ).removeClass('active');
    $( ".methods-slider-control__item" ).eq(state).addClass('active');
  });

  // phone-methods current time
  var today = new Date();
  var time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2);
  $('.phone-top-bar .clock').html(time);


  // show header when page loaded
  $('.header .heading-animation, .header-animation, .mobile-nav-toggle, .content-section-animation').removeClass('not-shown');

  // disable animation od mobile
  if( $(window).width() < 768 ){
    $('.not-shown').removeClass('not-shown');
  }
});


function isScrolledIntoView(elem, offset){
    var $elem = $(elem),
        $window = $(window),
        docViewTop = $window.scrollTop(),
        docViewBottom = docViewTop + $window.height(),
        elemTop = $elem.offset().top,
        elemBottom = elemTop + offset;

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
};

function removeClassWhenVisible(elem, className, offset){
  $(elem).each(function(){
    if(isScrolledIntoView($(this), offset)){
      $(this).removeClass(className);
    }
  })
};

function addClassWhenVisible(elem, className, offset){
  $(elem).each(function(){
    if(isScrolledIntoView($(this), offset)){
      $(this).addClass(className);
    }
  });
};


$(window).scroll(function(){
  removeClassWhenVisible('.not-shown', 'not-shown', 50);
});







// mobile nav
$('.mobile-nav-toggle input').on('change', function() {
    var navWrapper = $('.mobile-nav-content-wrapper');

    if($(this).is(":checked")) {
      if(navWrapper.hasClass('nav-hidden')){
        $("body").addClass("overflow-hidden");
        navWrapper.removeClass('nav-hidden');
      }
    } else {
      if(!navWrapper.hasClass('nav-hidden')){
        $("body").removeClass("overflow-hidden");
        navWrapper.addClass('nav-hidden');
      }
    }
});



// scroll to anchor
function scrollTo(target){
  $('html, body').stop().animate({
      'scrollTop': target
  }, 1500, 'easeInOutCirc');
};

$('a.scroll-to').on('click', function(e) {
    var target = this.hash,
        realTarget = target.slice(3),
        realTargetID = '#' + realTarget,
        $realTarget = $(realTargetID)
        offset = $realTarget.data('s-offset');

    if (offset == null){
      offset = 0;
    };

    scrollTo($realTarget.offset().top + offset);
});





$(document).ready(function (){
    if(window.location.hash) {
      // var hash = window.location.hash.substring(1);
      // alert (hash);

      var target = window.location.hash,
          realTarget = target.slice(3),
          realTargetID = '#' + realTarget,
          $realTarget = $(realTargetID)
          offset = $realTarget.data('s-offset');

      if (offset == null){
        offset = 0;
      };

      setTimeout(function() {
        scrollTo($realTarget.offset().top + offset);
      }, 500);


      // rushpay info modal
      if(target == '#rushpay'){
        $('#rushpay-info-modal').modal('show');
      }
    }
});
